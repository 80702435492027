<template>
  <div class="expertConsultation flexcolumnstart">
    <div class="loading" v-if="showloading">
      <van-loading size="32px" color="#18b09f">加载中...</van-loading>
    </div>

    <div v-else>
      <!-- <button @click="installApp">统计下载app次数</button> -->
      <div class="watchmore">
        <div class="flexcolumnend" style="height: 100%">
          <img src="../../images/pg1.png" class="imgse" />
        </div>
        <div class="flexrowbetween" style="flex-grow: 1">
          <div class="title">健康咨询，首次咨询免费问</div>
          <div class="knowmore" @click="knowDetail">
            <span>了解详情</span>
            <span class="borderleft"></span>
          </div>
        </div>
      </div>
      <div class="flexcolumnstart contents">
        <div
          class="flexrowbetween exportdetail"
          v-for="item in list"
          :key="item.iuid"
        >
          <div class="flexcolumnstart contentleft">
            <h3>
              {{ item.name }}<span>{{ item.titleValue }}</span>
            </h3>
            <p class="overellipsis hospitalname">
              {{ item.hospitalName }} {{ item.departmentName }}
            </p>
            <p class="hospitalintroduce">
              {{ item.doctorResume }}
            </p>
            <!-- <p class="price">
          <span style="margin-right: 16px">图文 ￥0.1</span
          ><span>视频 ￥0.1</span>
        </p> -->
          </div>
          <div class="flexcolumnstart contentright">
            <img :src="item.imgsrc" class="doctorimg" />
            <div class="askquestion" @click="openApp(item.iuid)">咨询</div>
          </div>
        </div>
      </div>
    </div>

    <van-dialog
      v-model="showadvice"
      title="树兰app当前版本较低，是否前往更新?"
      confirmButtonColor="#18b09f"
      confirmButtonText="是"
      cancelButtonText="否"
      @confirm="gotoinstall"
      :showCancelButton="showCancelButton"
    >
    </van-dialog>
    <van-dialog
      v-model="showinstall"
      title="是否前往下载树兰app?"
      confirmButtonColor="#18b09f"
      confirmButtonText="是"
      cancelButtonText="否"
      @confirm="gotoinstall"
      :showCancelButton="showCancelButton"
    >
    </van-dialog>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import getcollect from "../../../utils/databurying";
export default {
  data() {
    return {
      currentpagepath: "",
      comfrompath: "",
      showadvice: false,
      showloading: false,
      showinstall: false,
      showCancelButton: true,
      list: [
        // {
        //   name: "王培培",
        //   titleValue: "副主任医师",
        //   hospitalName: "树兰(杭州)医院",
        //   departmentName: "感染科",
        //   imgsrc:
        //     "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=9808295498994ab096fb0f7a23292f9b.jpg&isZipPic=true",
        //   iuid: "6ee1f5b8894b4f9b804c3696b83b6681", //测试
        //   doctorResume: "测试",
        // },
        {
          name: "盛国平",
          titleValue: "副主任医师",
          hospitalName: "树兰(杭州)医院",
          departmentName: "感染科",
          iuid: "80e3d40534b348b6b34cba6da6dd6a79",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=9808295498994ab096fb0f7a23292f9b.jpg&isZipPic=true",
          // iuid: "696cb509f0d7459285fd6652d5de8847", //测试
          doctorResume:
            "树兰（杭州）医院感染科副主任，国际多学科疑难病会诊中心主任。中国医师协会感染科医师分会秘书，浙江省医学会感染病学分会常委。曾经担任福建省晋江市医院副院长，博鳌超级医院副院长。从事人工肝治疗肝衰竭的临床实践及救治各类急慢性肝损伤、肝硬化以及细菌、病毒、真菌等感染性疾病10余年，并主持国家级重大专项、省科技厅课题等，发表SCI论文10余篇。",
        },
        {
          name: "周志博",
          titleValue: "住院医师",
          hospitalName: "树兰(杭州)医院",
          departmentName: "感染科",
          iuid: "6087a61ef2a2402496442008b7f727bc",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=FpNf_ElksA22U_EmUyyII3NEb2V7.png&isZipPic=true",
          doctorResume:
            "师从感染病学专家盛吉芳教授。毕业于浙江大学医学院，获临床医学专业硕士学位。并于2013.9-2016.06于浙江大学医学院附属第一医院完成住院医师规范化培训。",
        },
        {
          name: "楼丹峰",
          titleValue: "主治医师",
          hospitalName: "树兰(杭州)医院",
          departmentName: "感染科",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=FjgC1_bNCoN9GIq4nVwU2Oct0LNF.png&isZipPic=true",
          iuid: "132fa8c0d32511e6a13f2c337a848930",
          doctorResume:
            "2009年毕业于浙江大学医学院临床医学系，并获硕士研究生学位，已从事感染科临床工作近10年。",
        },
        {
          name: "吴佳俊",
          titleValue: "住院医师",
          hospitalName: "树兰(杭州)医院",
          departmentName: "感染科",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=Fi3MluvJObqfgLM0dIf9GjjxWyul.png&isZipPic=true",
          iuid: "992435dcca4b4ee0aa817e574e1d93f6",
          doctorResume:
            "毕业于浙江大学医学院，医学硕士。现就职于树兰（杭州）医院，从事感染科临床工作。",
        },
        {
          name: "黄勇",
          titleValue: "住院医师",
          hospitalName: "树兰(杭州)医院",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=d71ed5686e3e4c558a600c4673119b8b.jpg&isZipPic=true",
          departmentName: "感染科",
          iuid: "f9e61194e83244eebcb19e5c45e6606d",
          doctorResume:
            "毕业于浙江大学7年制专业，医学硕士。现就职于树兰（杭州）医院从事感染科临床工作。",
        },
        {
          name: "李楠",
          titleValue: "住院医师",
          hospitalName: "树兰(杭州)医院",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=72023adafef54dec8e6fcffdeecc236f.jpeg&isZipPic=true",
          departmentName: "感染科",
          iuid: "5128b7d3c05f4e4aba6d77cd8a9e2f28",
          doctorResume:
            "毕业于温州医科大学， 现就职于树兰（杭州）医院，从事感染科临床工作。",
        },
        {
          name: "陈日钗",
          titleValue: "主治医师",
          hospitalName: "树兰(杭州)医院",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=d4ba4fdab1c2430fb1706e2ec2404277.jpg&isZipPic=true",
          departmentName: "感染科",
          iuid: "06dc9ca7ca6049d691a95878b29f9842",
          doctorResume:
            "从事感染科工作10年，对感染科常规疾病，感染性发热，各种病毒性肝炎，自身免疫性肝炎，肝硬化并发症和肝癌有一定诊治经验。",
        },
        {
          name: "刘宗明",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=20a2307b5926448daa3f70d3e495074c.jpg&isZipPic=true",
          titleValue: "住院医师",
          hospitalName: "树兰(杭州)医院",
          departmentName: "感染科",
          iuid: "b3692b79057445939438075f9f0c19aa",
          doctorResume:
            "毕业于泰山医学院，现就职于树兰（杭州）医院，从事感染科ICU工作。",
        },
        {
          name: "彭聪高",
          imgsrc:
            "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=Fu7lVd4jahJ4nkAZsZENnU1jfsXm.png&isZipPic=true",
          titleValue: "住院医师",
          hospitalName: "树兰(杭州)医院",
          departmentName: "感染科",
          iuid: "b536dd125f3e4560a8676a506683691f",
          doctorResume:
            "毕业于浙江大学医学院，获医学硕士学位，师从我国感染病肝病学专家李兰娟院士。现就职于树兰（杭州）医院从事感染科临床工作。",
        },
        // {
        //   name: "郭飞飞",
        //   titleValue: "住院医师",
        //   imgsrc:
        //     "https://cloud-hospital.shulan.com/ui-pocket-hospital/v1/image/download?fileName=Fi46N6lkc1aOcCRRVQZlBgzJOPXZ.png&isZipPic=true",
        //   hospitalName: "树兰(杭州)医院",
        //   departmentName: "感染科",
        //   iuid: "7728624a8ff44ee8b6e189b2f489d850",
        //   doctorResume:
        //     "毕业于浙江大学医学院，临床医学感染病学硕士，师从李兰娟院士。现就职于树兰（杭州）医院，从事感染科临床工作。",
        // },
      ],
      shulanToken: "",
      userInformation: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from, "页面");
    next((vm) => {
      vm.currentpagepath = to.fullPath;
      vm.comfrompath = from.fullPath;
    });
  },
  methods: {
    //测试下载肝脏脂肪app
    installApp() {
      console.log(navigator.userAgent, "navigator.userAgent");
      var agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf("huawei") > 0) {
        window.open("https://appgallery.huawei.com/#/app/C104152127", "_href");
      } else {
        alert("不是华为手机");
      }
    },
    openweixin() {
      let gid = uuidv4().replace(/-/g, "");
      let p = this.currentpagepath;
      let t = "click";
      let r = uuidv4().replace(/-/g, "");
      let v = "下载树兰app";
      let p0 = this.comfrompath;
      getcollect({ gid, p, t, r, v, p0 });
      // window.open("weixin://dl/business/?t=F3iL3ynafsl", "_href");
    },
    knowDetail() {
      this.$router.push({
        path: "/liverByConsulting/detail",
      });
    },
    gotoinstall() {
      //上报下载树兰app
      this.openweixin();
      this.showadvice = false;
      this.showinstall = false;
      this.isinstall();
    },
    isinstall() {
      var agent = navigator.userAgent.toLowerCase();
      if (agent.indexOf("huawei") > 0) {
        window.open("https://appgallery.huawei.com/#/app/C102253437", "_href");
      } else {
        var src = "https://a.vmall.com/appdl/C102253437";
        var iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = src;
        document.getElementsByTagName("body")[0].appendChild(iframe);
      }
    },
    async openApp(Iuid) {
      this.userInformation = await window.android.jsGetDataInfo();
      let slVersionAnswer = await window.android.getShulanVersion();
      let jsslVersionAnswer = JSON.parse(slVersionAnswer);
      let isInstall = jsslVersionAnswer.isInstall; //是否安装树兰app
      let versionName = jsslVersionAnswer.versionName; //树兰app版本号
      //判断是否安装且版本号是否大于某个版本
      let isrequireinstall = versionName > "1.8.1";
      //1判断是否已经下载树兰app
      //2判断当前版本号
      if (!isInstall) {
        this.showinstall = true;
      } else if (!isrequireinstall) {
        this.showadvice = true;
      } else {
        let keyword = "jumpToDoctorIndexfromHW"; //跳转app医生咨询详情
        let doctorIuid = Iuid; //医生iuid
        let symptoms = this.userInformation; //病情描述app中直接接口获取
        let url = `shulanuser://shulanfromHuaWei?keyword=${keyword}&doctorIuid=${doctorIuid}&symptoms=${symptoms}&shulanToken=${this.shulanToken}`;
        // alert(url);
        console.log("跳转地址", url);
        this.$dialog
          .confirm({
            message: "打开树兰app?",
            confirmButtonColor: "#18b09f",
          })
          .then(() => {
            console.log("跳转地址", url);
            window.open(url, "_self");
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    askdoctor(researchOpenId, researchToken, shulanToken) {
      let channelId = researchOpenId; //this.$router.query.openID
      let channel = "huawei";
      let token = researchToken;
      if (
        shulanToken == "" ||
        shulanToken == undefined ||
        shulanToken == null
      ) {
        //调用接口查询树兰token
        this.$axios
          .post("/im/channel/reg", {
            channelId: channelId,
            channel: channel,
            channelToken: token,
          })
          .then((res) => {
            console.log("res", res);
            if (res.data.code == 1) {
              this.shulanToken = res.data.data;
              //设置树兰token接口 （js 调用 android）
              window.android.setShulanToken(this.shulanToken);
              //获取个人信息&测量数据 -- 后端自动解密 app里面绑定id时直接返回个人信息
              // this.userInformation = window.android.jsGetDataInfo();
              // console.log("个人测量数据", window.android.jsGetDataInfo());
              this.showloading = false;
            } else {
              // this.$toast(res.data.msg);
              //强制刷新token
              window.android.asyncGetShulanAuthorization(true);
            }
          })
          .catch((error) => {
            console.log("errors", error);
          });
      } else {
        //设置树兰token接口 （js 调用 android）
        window.android.setShulanToken(this.shulanToken);
        this.shulanToken = shulanToken;
        //获取个人信息&测量数据 -- 后端自动解密 app里面绑定id时直接返回个人信息
        // this.userInformation = window.android.jsGetDataInfo();
        // console.log("个人测量数据", window.android.jsGetDataInfo());
        this.showloading = false;
      }
    },
    //判断是否安装树兰app及app版本号
    getShulanVersion() {
      window.android.asyncGetShulanAuthorization(false);
    },
    //回调token给h5 （android回调js端方法）android异步执行完后，把token等参数回传给js
    callbackShulanToken(param) {
      let params = JSON.parse(param);
      console.log(params, "android数据回传");
      let researchOpenId = params.researchOpenId;
      let researchToken = params.researchToken;
      let shulanToken = params.shulanToken;
      //调用树兰后端接口 传参后获取树兰token，若接口返回提醒token过期，则重新调用js(HaWei)接口，并传入强制刷新researchToken标志
      this.askdoctor(researchOpenId, researchToken, shulanToken);
    },
  },
  mounted() {
    this.getShulanVersion();
    //将要给原生调用的方法挂载到 window 上面
    window.callbackShulanToken = this.callbackShulanToken;
  },
};
</script>
<style lang="scss" scoped>
.expertConsultation {
  .loading {
    width: 100%;
    position: absolute;
    top: 40%;
    text-align: center;
  }
  font-size: 15px;
  padding: 28px;
  .contents {
    margin: 20px 0 40px;
  }
  .exportdetail {
    border-bottom: 1px solid #f2f2f2;
    padding: 20px 0;
    .contentleft {
      margin-right: 10px;
      h3 {
        font-size: 35px;
        color: #272929;
        font-weight: 500;
        span {
          font-size: 25px;
          color: #4b4d4d;
          margin-left: 14px;
        }
      }

      .hospitalname {
        margin: 10px 0 10px;
        font-size: 30px;
        color: #4b4d4d;
      }
      .hospitalintroduce {
        font-size: 25px;
        color: #9a9e9e;
        font-weight: normal;
      }
      .price {
        margin-top: 10px;
        color: #4b4d4d;
        font-size: 0.12rem;
      }
    }
    .contentright {
      align-items: center;
      .doctorimg {
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }
      .askquestion {
        width: 130px;
        line-height: 60px;
        color: #fff;
        font-size: 30px;
        text-align: center;
        border-radius: 30px;
        background-color: #18b09f;
        margin-top: 10px;
      }
    }
  }
  .watchmore {
    width: 100%;
    background: rgb(255, 240, 200);
    padding: 10px 10px 0;
    border-radius: 20px;
    // line-height: 80px;
    // background: url("../../images/advice.png") no-repeat center;
    // background-size: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .imgse {
      width: 82px;
      margin-left: 10px;
    }
    .title {
      // margin-left: 120px;
      color: rgb(192, 142, 68);
      font-weight: bold;
      font-size: 28px;
    }
    .knowmore {
      font-size: 24px;
      padding-right: 25px;
      color: rgb(192, 142, 68);
      display: flex;
      flex-direction: row;
      align-items: center;
      .borderleft {
        width: 12px;
        height: 12px;
        border-top: 2px solid rgb(192, 142, 68);
        border-left: 2px solid rgb(192, 142, 68);
        transform: rotate(135deg);
        margin-left: 5px;
      }
    }
  }
}
</style>
